import React from 'react'
import { Router } from '@reach/router'

import withTemplate from '~components/Template/withTemplate'

const ArticlesIndex = () => {
	return <div className="ArticlesIndex">index</div>
}

const Article = ({ id }: { id?: string; path?: string }) => {
	return <div className="Article">Article {id}</div>
}

const ArticlesRouter = () => {
	return (
		<div className="Articles">
			<Router basepath="/articles">
				{/* @ts-ignore */}
				<ArticlesIndex path="/" />
				<Article path="/:id" />
			</Router>
		</div>
	)
}
export default withTemplate(ArticlesRouter)
